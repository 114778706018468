import HttpService from "./htttp.service";

class ReadingService {

  getLatestReadings = async () => {
    return await HttpService.get("/reading/latest/");
  };

  getReadingsByIdAndDate = async (deviceId, start, end) => {
    return await HttpService.get(`/reading/${deviceId}?startTime=${start}&endTime=${end}`);
  };

}

export default new ReadingService();
