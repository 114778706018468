import formatDeviceName from "./formatDeviceName";

const formatDate = (date) => {
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();
    return `${day}-${month}-${year}`;
};

const convertDateTime = (date) => {
    const parsedDate = new Date(date);
    const options = {
        timeZone: 'Asia/Singapore',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    };
    return parsedDate.toLocaleString('en-GB', options).replace(',', '');
};

const formatDeviceData = (devices) => {
    return devices.map((device) => ({
        ...device,
        deviceName: formatDeviceName(device.deviceName),
        // deviceType: device.deviceType,
        dateCreated: formatDate(device.dateCreated),
        // dateUpdated: convertDateTime(device.dateUpdated),
        lastUpdated: convertDateTime(device.lastUpdated)
    }));
};

export default formatDeviceData;