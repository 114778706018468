import HttpService from "./htttp.service";

class DeviceService {

  addDevice = async (payload) => {
    return await HttpService.post('device', payload);
  };

  // listDevices = async () => {
  //   return await HttpService.get('device');
  // };

  listDevices = async (deviceNames) => {
    return await HttpService.get('device/findAll/' + deviceNames);
  };

  listDepartmentRooms = async () => {
    return await HttpService.get('device/department/rooms');
  };

  getDevice = async (deviceId) => {
    return await HttpService.get('device/' + deviceId);
  };

  deleteDevice = async (deviceId) => {
    return await HttpService.delete('device/' + deviceId);
  };

  modifyDevice = async (deviceId, payload) => {
    return await HttpService.put('device/' + deviceId, payload);
  };

}

export default new DeviceService();
