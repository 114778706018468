/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React components
import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDBadge from "../../../components/MDBadge";
import MDButton from "../../../components/MDButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlined from '@mui/icons-material/ClearOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import deviceTypeConfig from "../../../utils/deviceTypeConfig";
import deviceModeConfig from "../../../utils/deviceModeConfig";
import formatDeviceData from "../../../utils/deviceDataFormat";

const DeviceTable = (
    devices,
    devicePoints,
    viewModifyDevice,
    viewDeleteDevice,
    viewAddPoint,
    viewModifyPoint,
    viewDeletePoint,
    handleDeviceClick,
    handleDeviceLocationClick,
    role
) => {
    const formattedDevices = formatDeviceData(devices);
    const showCol = role > 0;

    const Topic = ({ topic }) => {
        const handleCopyClick = async () => {
            try {
                await navigator.clipboard.writeText(topic);
                alert("Copied MQTT Topic to clipboard:\n" + topic);
            } catch (err) {
                console.error("Unable to copy MQTT Topic to clipboard.", err);
                alert("Copy to clipboard failed.");
            }
        };

        return (
            <MDBox display="flex" lineHeight={1}>
                <MDBox>
                    <MDButton
                        size="medium"
                        variant="text"
                        iconOnly={true}
                        color="text"
                        onClick={handleCopyClick}
                    >
                        <ContentCopyIcon variant="filled" />
                    </MDButton>
                    <MDTypography component="span" variant="caption" color="text" fontWeight="regular">
                        {topic}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    };
// Enhanced columns with custom sorting
    const columns = [
        {
            Header: "Device Name",
            accessor: "deviceName",
            align: "left",
            sortType: (rowA, rowB) => {
                // Extract the device name from the rendered component
                const nameA = rowA.original.deviceName.props.children.toString().toLowerCase();
                const nameB = rowB.original.deviceName.props.children.toString().toLowerCase();
                return nameA.localeCompare(nameB);
            }
        },
        ...(showCol ? [{
            Header: "Device Type",
            accessor: "deviceType",
            align: "center",
            sortType: (rowA, rowB) => {
                // Safely extract the mode of control text
                const typeA = rowA.original.deviceType?.props?.children || '';
                const typeB = rowB.original.deviceType?.props?.children || '';

                // Convert to lowercase for case-insensitive sorting
                return typeA.toString().toLowerCase().localeCompare(typeB.toString().toLowerCase());
            }
        }] : []),
        ...(showCol ? [{
            Header: "MQTT Topic",
            accessor: "topic",
            sortType: (rowA, rowB) => {
                const a = rowA.original.deviceMqttTopic;
                const b = rowB.original.deviceMqttTopic;
                return a.localeCompare(b);
            }
        }] : []),
        ...(showCol ? [{
            Header: "Measuring Points",
            accessor: "measuringPoints",
            align: "center"
        }] : []),
        {
            Header: "Latest Reading",
            accessor: "lastReading",
            align: "center",
            sortType: (rowA, rowB) => {
                // Extract the numeric value from the badge content
                const extractValue = (reading) => {
                    if (reading.props.children === 'Loading...') return -Infinity;
                    const value = parseFloat(reading.props.children.replace('°C', ''));
                    return isNaN(value) ? -Infinity : value;
                };
                const a = extractValue(rowA.original.lastReading);
                const b = extractValue(rowB.original.lastReading);
                return a - b;
            }
        },
        {
            Header: "Last Updated",
            accessor: "lastUpdated",
            align: "center",
            sortType: (rowA, rowB) => {
                // Safely extract the date text from the React component
                const dateTextA = rowA.original.lastUpdated?.props?.children || '';
                const dateTextB = rowB.original.lastUpdated?.props?.children || '';

                // If either date is empty, handle edge cases
                if (!dateTextA) return dateTextB ? -1 : 0;
                if (!dateTextB) return 1;

                // Parse the specific date format used in convertDateTime
                const parseCustomDateTime = (dateString) => {
                    // Example format: "Mon 05/11/2024 02:30:45 PM"
                    const parts = dateString.split(' ');
                    const dateParts = parts[1].split('/');
                    const timeParts = parts[2].split(':');
                    const isPM = parts[3] === 'PM';

                    // Construct date with proper formatting
                    let hours = parseInt(timeParts[0]);
                    if (isPM && hours !== 12) hours += 12;
                    if (!isPM && hours === 12) hours = 0;

                    return new Date(
                        parseInt(dateParts[2]),  // year
                        parseInt(dateParts[1]) - 1,  // month (0-indexed)
                        parseInt(dateParts[0]),  // day
                        hours,  // hours
                        parseInt(timeParts[1]),  // minutes
                        parseInt(timeParts[2])   // seconds
                    ).getTime();
                };

                // Compare timestamps
                return parseCustomDateTime(dateTextA) - parseCustomDateTime(dateTextB);
            }
        },
        {
            Header: "Mode of Control",
            accessor: "modeOfControl",
            align: "center",
            sortType: (rowA, rowB) => {
                // Safely extract the mode of control text
                const modeA = rowA.original.modeOfControl?.props?.children || '';
                const modeB = rowB.original.modeOfControl?.props?.children || '';

                // Convert to lowercase for case-insensitive sorting
                return modeA.toString().toLowerCase().localeCompare(modeB.toString().toLowerCase());
            }
        },
        {
            Header: "No. of Days < 25 °C",
            accessor: "daysGT25",
            align: "center",
            sortType: (rowA, rowB) => {
                // Extract the numeric value from the badge content
                const extractValue = (days) => {
                    if (days.props.badgeContent === 'Loading...') return -Infinity;
                    const value = parseInt(days.props.badgeContent, 10);
                    return isNaN(value) ? -Infinity : value;
                };

                const a = extractValue(rowA.original.daysGT25);
                const b = extractValue(rowB.original.daysGT25);
                return a - b;
            }
        },
        ...(showCol ? [{ Header: "Device Location", accessor: "location", align: "center" }] : []),
        ...(showCol ? [{ Header: "Edit Device", accessor: "editDevice", align: "center" }] : []),
    ].filter(Boolean);

    // Define rows
    const rows = formattedDevices.map((device) => {
        const baseRow = {
            deviceName: (
                <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    color="text"
                    fontWeight="bold"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDeviceClick(device.deviceId)
                    }}
                >
                    {device.deviceName.startsWith("Airwits")
                        ? device.deviceName.slice(13).toUpperCase()
                        : device.deviceName.toUpperCase}
                </MDTypography>
            ),
            lastReading: (
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="bold"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDeviceClick(device.deviceId)
                    }}
                >
                    {(device.lastReading !== 'Loading...')
                        ? `${device.lastReading}\u00B0C`
                        : 'Loading...'}
                </MDTypography>
            ),
            modeOfControl: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {deviceModeConfig[device.deviceType + ""]}
                </MDTypography>
            ),
            daysGT25: (
                <MDBadge
                    badgeContent={device.daysGT25}
                    variant="contained"
                    color={device.daysGT25 < 3 ? 'success' : 'error'}
                    container
                    component="a"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDeviceClick(device.deviceId)
                    }}
                />
            ),
            lastUpdated: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {device.lastUpdated}
                </MDTypography>
            ),
        };

        if (showCol) {
            baseRow['topic'] = <Topic topic={device.deviceMqttTopic} />;
            baseRow['measuringPoints'] = (
                <MDButton>
                    {!devicePoints[device.deviceId] ? (
                        <b></b>
                    ) : (
                        devicePoints[device.deviceId].map((point) => (
                            <span key={point.pointId}>
                                <MDTypography
                                    sx={{ padding: 1, margin: 1, minHeight: 5, maxHeight: 10 }}
                                    component="a"
                                    href="#"
                                    variant="caption"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => viewModifyPoint(point)}
                                >
                                    {point.pointName}
                                </MDTypography>
                                <MDButton
                                    sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 10, minHeight: 5, maxHeight: 10 }}
                                    onClick={() => viewDeletePoint(point)}
                                >
                                    <ClearOutlined />
                                </MDButton>
                                <br />
                            </span>
                        ))
                    )}
                    <MDButton
                        sx={{ padding: 1, margin: 1, minHeight: 5, maxHeight: 10 }}
                        onClick={() => viewAddPoint(device)}
                    >
                        <LibraryAddOutlinedIcon variant="outlined" />
                    </MDButton>
                </MDButton>
            );
            baseRow['deviceType'] = (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {deviceTypeConfig[device.deviceType + ""]}
                </MDTypography>
            );
            baseRow['threshold'] = (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {device.threshold
                        ? `${device.threshold}°C`
                        : `${device.deviceName.toLowerCase().includes('lab') ? 24 : 25}°C`}
                </MDTypography>
            );
            baseRow['location'] = (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium"
                              onClick={() => { // when clicking the location details, the mapModal will pop up.
                                  handleDeviceLocationClick(device)
                              }}
                              style={{ cursor: 'pointer' }}
                >
                    {(device.deviceLatitude ?? '1.3102731537435788') + ', ' + (device.deviceLongitude ?? '103.7803697688016')}
                </MDTypography>
            );
            baseRow['editDevice'] = (
                <MDBox display="flex">
                    <MDBox>
                        <MDButton
                            sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
                            onClick={() => viewModifyDevice(device)}
                        >
                            <EditOutlinedIcon variant="filled" />
                        </MDButton>
                        <MDButton
                            sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
                            onClick={() => viewDeleteDevice(device)}
                        >
                            <DeleteSweepOutlinedIcon variant="filled" />
                        </MDButton>
                    </MDBox>
                </MDBox>
            );
        }
        return baseRow;
    });

    return { columns, rows };
};

export default DeviceTable;
