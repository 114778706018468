import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

const DepartmentSelector = ({ open, setOpen, login, email, password, role, departmentOptions }) => {
    const [department, setDepartment] = useState("");
    const [error, setError] = useState(false);

    // alert(JSON.stringify(departmentOptions));

    const handleClose = () => {
        if (!department) {
            setError(true); // Trigger error if no department selected
            return;
        }
        login(email, password, role, department);
        setOpen(false);
    };

    return (
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <MDTypography variant="h5" color="info">
                    Please select your Department:
                </MDTypography>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth error={error} sx={{ mt: 2 }}>
                    <InputLabel id="department-label">Department</InputLabel>
                    <Select
                        labelId="department-label"
                        id="department"
                        value={department}
                        onChange={(e) => {
                            setDepartment(e.target.value);
                            setError(false); // Reset error when selection changes
                        }}
                        label="Department"
                        sx={{
                            height: 50,
                            fontSize: '1rem',
                            width: '100%',
                        }}
                        aria-labelledby="department-label"
                        aria-describedby="department-helper-text"
                    >
                        {departmentOptions.map((option) => (
                            <MenuItem key={option['roomNos']} value={option['roomNos']}>
                                {option['department']}
                            </MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText id="department-helper-text">Department is required</FormHelperText>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <MDButton onClick={handleClose}>
                    <MDTypography variant="h6" color="info">Ok</MDTypography>
                </MDButton>
            </DialogActions>
        </Dialog>
    );
};

export default DepartmentSelector;
