import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";
import DepartmentSelector from "./components/DepartmentSelector";

// Services
import DeviceService from "services/device-service";
import AuthService from "services/auth-service";
import { AuthContext } from "context";

// Images
import bgImage from "assets/images/sp-bg.jpg";

function Login() {

  const authContext = useContext(AuthContext);
  const [credentialsErros, setCredentialsError] = useState(null);
  const [openDepartmentSelector, setOpenDepartmentSelector] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [signInDisabled, setSignInDisabled] = useState(false);
  const [role, setRole] = useState(0);
  const [departmentData, setDepartmentData] = useState([]);
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false
  });

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    // --- Enable email 2FA --- //
    if (
        !showPasswordField &&
        inputs.email !== process.env.REACT_APP_ADMIN_EMAIL &&
        inputs.email !== process.env.REACT_APP_GUEST_EMAIL
    ) {
      setSignInDisabled(true);
      try {
        const response = await AuthService.resetPassword({email: inputs.email.trim()});
        alert(response);
        setShowPasswordField(true);
      } catch (e) {
        alert(e);
      } finally {
        setSignInDisabled(false);
      }
      return;
    } else if (inputs.password.trim().length === 0) {
      alert("Please enter the Passcode");
      return;
    }
    // ------------------------------ //

    try {
      const response = await AuthService.login({
        email: inputs.email,
        password: inputs.password
      });
      document.cookie = `credential=${inputs.email}; expires=Sun, 31 Dec 2025 23:59:59 GMT; path=/`;
      setRole(response.role);

      DeviceService.listDepartmentRooms().then((res) => {
        // const deptRoomsMap = {}
        // res.forEach(e => {
        //   deptRoomsMap[e.department] = e.roomNos;
        // });
        //
        // const depts = [
        //   { value: "T32,T33,T34,3A05,T3A2,T3A3,T3A4,T532C,T532D,T54,W317A,W417,W415,W51", label: "ABE" },
        //   { value: "A152,T111", label: "ACAD" },
        //   { value: "A154", label: "ADM" },
        //   { value: "L15,T102", label: "AQR" },
        //   { value: "T1524,T1521", label: "AS" },
        //   { value: "T11A5,T11A604,T11A60,T11A615,T11A613,T11A619,T11A611,T11A616,T11A610,T11B509A,T11B6,W124,W125,W319A", label: "CLS" },
        //   { value: "A11", label: "COMMS" },
        //   { value: "A155", label: "DEV" },
        //   { value: "A133,A132,W5A210K", label: "ED" },
        // ]

        setDepartmentData(res);
      });
      setOpenDepartmentSelector(true);
    } catch (res) {
      const msg = res.hasOwnProperty("message") ? res.message : res.toString();
      if (msg.toLowerCase().includes("expire")) {
        alert("Passcode Expired");
        window.location.reload(false);
      } else {
          setCredentialsError(msg);
      }
    }
    return () => {
      setInputs({
        email: "",
        password: "",
      });
      setErrors({
        emailError: false,
        passwordError: false,
      });
    };
  };

  return (
    <BasicLayoutLanding image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </MDBox>
            <MDBox mb={2}> {
              (
                  showPasswordField ||
                  inputs.email === process.env.REACT_APP_ADMIN_EMAIL ||
                  inputs.email === process.env.REACT_APP_GUEST_EMAIL
              ) &&
              <MDInput
                type="password"
                label="Passcode"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
            }
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={signInDisabled}>
                sign in
              </MDButton>
            </MDBox>
            {credentialsErros && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {credentialsErros}
              </MDTypography>
            )}
            <MDBox mb={1} textAlign="center">
              <DepartmentSelector
                  open={openDepartmentSelector}
                  setOpen={setOpenDepartmentSelector}
                  login={authContext.login}
                  email={inputs.email}
                  password={inputs.password}
                  role={role}
                  departmentOptions={departmentData}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
     </BasicLayoutLanding>
  );
}

export default Login;
