/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 */

import React, { useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";

// @mui material components
import { Link } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Footer from "../../examples/Footer";

import CpuMetric from "./components/CpuMetric";

// Services and context
import { AuthContext } from "context";
import DeviceService from "../../services/device-service";
import DepartmentSelector from "../../auth/login/components/DepartmentSelector";

const Dashboard = () => {

    const authContext = useContext(AuthContext);
    const [deviceIdList, setDeviceIdList] = useState("");
    const [departmentData, setDepartmentData] = useState([]);
    const [openDepartmentSelector, setOpenDepartmentSelector] = useState(false);
    const navigate = useNavigate();

    const openReportPage = () => {
        navigate("/report");
    };

    useEffect(() => {
        const accessibleDevice = authContext.address;
        try {
            DeviceService.listDevices(accessibleDevice).then((devices) => {
                setDeviceIdList(devices.map(device => device.deviceId).join(","));
            });
            DeviceService.listDepartmentRooms().then((res) => {
                setDepartmentData(res);
            });
        } catch (error) {
            console.log('--- Dashboard: Error listing devices ---\n' + error);
            alert('The server is experiencing higher than expected user connections, please try again later.\n\nWe apologize for the inconvenience.');
        }
    });

    return (
        <DashboardLayout>
            <div style={{ display: "flex", flexDirection: "column", minHeight:"95vh", justifyContent: "space-between" }}>
                <div>
                    <DashboardNavbar />
                    {
                        authContext.role > 0 && (
                            <MDBox>
                                <MDBox mt={4}>
                                    <CpuMetric />
                                </MDBox>
                            </MDBox>
                        )
                    }
                    <MDBox mt={4} pb={2}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <MDTypography
                                            variant="h6"
                                            color="white"
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            Summary of all facilities under &nbsp;
                                            <Link
                                                to="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenDepartmentSelector(true);
                                                }
                                            }>
                                                {authContext.department}
                                            </Link>
                                            &nbsp; for the current month
                                        </MDTypography>
                                        {/*<MDBox style={{display: 'inline-flex', alignItems: 'center',gap: '1rem'}}>*/}
                                        {/*    <MDButton size="medium" onClick={openReportPage}>*/}
                                        {/*        Generate Report*/}
                                        {/*    </MDButton>*/}
                                        {/*</MDBox>*/}
                                    </MDBox>
                                    <MDBox py={3} px={2} borderRadius="lg">
                                        <iframe
                                            src={`${process.env.REACT_APP_CHART_URL}?deviceIdList=${deviceIdList}`}
                                            frameBorder="0"
                                            width="100%"
                                            height="600px"
                                        >
                                        </iframe>
                                    </MDBox>
                                    <MDBox mb={1} textAlign="center">
                                        <DepartmentSelector
                                            open={openDepartmentSelector}
                                            setOpen={setOpenDepartmentSelector}
                                            departmentObjs={Object.fromEntries(departmentData.map(x => [x.department, x['roomNos']]))}
                                            selectionHandler={(rooms, department) => {
                                                authContext.refreshView(rooms, department);
                                            }}
                                        />
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer />
            </div>
        </DashboardLayout>
    );

};

export default Dashboard;
