import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DefaultLineChart from '../../../../examples/Charts/LineCharts/DefaultLineChart';
import ReadingService from '../../../../services/reading-service';
import DeviceService from "../../../../services/device-service";
import MeasurementService from "../../../../services/measurement-service";
import FileService from '../../../../services/file-service';
import AggregationService from "../../../../services/aggregation-service";
import formatDateTime from '../../../../utils/formatDateTime';
import {AuthContext} from "../../../../context";


// const AGGREGATION_FILE = process.env.REACT_APP_AGGREGATION_FILE;
// const DATA_BUCKET = process.env.REACT_APP_DATA_BUCKET;

// Todo: Need to obtain from settings
// const THRESHOLD = { "temperature": 25.0, "periodActivity": 0.0 };
// const DATA_DURATION_DAYS = 30;

const DeviceDetailsPage = () => {
    const { deviceId: deviceIdParam } = useParams();
    const deviceId = Number(deviceIdParam);
    const [chartData, setChartData] = useState([]);
    const [deviceName, setDeviceName] = useState('');
    const [measurementPoints, setMeasurementPoints] = useState([]);
    const [latestReading, setLatestReading] = useState(null);
    // const [weeklyAverage, setWeeklyAverage] = useState(null);
    const [daysExceedThreshold, setDaysExceedThreshold] = useState(null);
    const [threshold, setThreshold] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const authContext = useContext(AuthContext);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '90%',
        bgcolor: 'background.paper',
        borderRadius: 4,
        boxShadow: 24,
        p: isMobile ? 2 : 4,
    };

    const navigate = useNavigate();

    const handleDeviceClose = () => {
        navigate(-2);
    };

    useEffect(() => {
        if (deviceId) {
            const fetchDeviceDetails = async () => {
                const deviceDetails = await DeviceService.getDevice(deviceId);
                setDeviceName(deviceDetails.deviceName);
                const measurementResponse = await MeasurementService.listMeasurementPoints();
                const measurementResults = measurementResponse.collection;
                const filteredMeasurementPoints = measurementResults.filter(point => point.deviceId === deviceId);
                setMeasurementPoints(filteredMeasurementPoints); // Filter measurement points by deviceId

                // Fetch aggregation data

                // --- Unable to use current credential as one-time password will be changed after login --- //
                // const jsonExceedCount = await AggregationService.getExceedingDayCount(authContext.email, authContext.password);
                const jsonExceedCount = await AggregationService.getExceedingDayCount(
                    process.env.REACT_APP_ANALYTICS_API_USERNAME,
                    process.env.REACT_APP_ANALYTICS_API_PASSWORD
                );
                const exceedCountData = JSON.parse(jsonExceedCount);
                const exceedCountMap = {};
                const lowerThresholdMap = {};
                exceedCountData.forEach(e => {
                    lowerThresholdMap[e.deviceId + ""] = e.Threshold_Lower;
                });
                exceedCountData.forEach(e => {
                    exceedCountMap[e.deviceId + ""] = e.Days_Exceed_Threshold;
                });
                setDaysExceedThreshold(exceedCountMap[deviceId + ""]);
                setThreshold(lowerThresholdMap[deviceId + ""]);

                // Fetch latest reading
                const readings = await ReadingService.getLatestReadings();
                // const deviceReading = readings.find(r => r.deviceId === deviceId && r.dateTime === deviceDetails.dateUpdated);
                const deviceReading = readings.find(r => r.deviceId === deviceId);
                if (deviceReading) {
                    setLatestReading({
                        // value: deviceReading.pointData.value,
                        // time: formatDateTime(new Date(deviceReading.dateTime)).formattedDateTimeFull
                        value: deviceReading.value,
                        time: formatDateTime(new Date(deviceReading.dateTime)).formatted12hrWeekday
                    });
                }
            };
            fetchDeviceDetails();
        }
    }, [deviceId, authContext.email, authContext.password]);

    // useEffect(() => {
    //     if (deviceId && measurementPoints.length > 0) {
    //         const fetchData = async () => {
    //             const startTime = new Date();
    //             startTime.setHours(startTime.getHours() - 24 * DATA_DURATION_DAYS);
    //             const endTime = new Date();
    //             const readingResults = await ReadingService.getReadingsByIdAndDate(
    //                 deviceId,
    //                 startTime.toISOString(),
    //                 endTime.toISOString()
    //             );
    //             const labels = readingResults.map(reading => formatDateTime(new Date(reading.dateTime)).formattedDateTime).reverse();
    //             const data = readingResults.map(reading => reading.pointData.value).reverse();
    //             const lineThreshold = readingResults.map(reading => THRESHOLD[measurementPoints[0].pointName]);
    //             setChartData({
    //                 labels,
    //                 datasets: [
    //                     {
    //                         label: measurementPoints[0].pointName,
    //                         data,
    //                         color: 'info',
    //                         fill: true,
    //                         tension: 1,
    //                     },
    //                     {
    //                         label: THRESHOLD[measurementPoints[0].pointName] + getUnit(measurementPoints[0].pointName),
    //                         data: lineThreshold,
    //                         color: 'warning',
    //                         fill: true,
    //                         tension: 1,
    //                     },
    //                 ],
    //             });
    //             if (readingResults.length > 0) {
    //                 const latest = readingResults[0];
    //                 setLatestReading({
    //                     value: latest.pointData.value,
    //                     time: formatDateTime(new Date(latest.dateTime)).formattedDateTimeFull
    //                 });
    //             }
    //         };
    //         fetchData();
    //     }
    // }, [deviceId, measurementPoints]);

    // set the unit for readings
    const getUnit = (pointName) => {
        return pointName === 'temperature' ? '°C' : '';
    };

    return (
        <DashboardLayout>
            <div style={{ display: "flex", flexDirection: "column", minHeight:"95vh", justifyContent: "space-between" }}>
                <div>
                    <DashboardNavbar />
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Device Reading
                                        </MDTypography>
                                        <MDBox style={{display: 'inline-flex', alignItems: 'center',gap: '1rem'}}>
                                            <MDButton iconOnly={true} size="medium" onClick={handleDeviceClose}>
                                                <CloseIcon />
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox pt={3} pb={3} mx={4}>
                                        <MDBox>
                                            <MDTypography variant="h5" component="h2" textTransform="capitalize">
                                                {deviceName}
                                            </MDTypography>
                                            <MDBox style={{ display: 'flex', alignItems: 'start', gap: '6rem' }}>
                                                <MDBox>

                                                    {
                                                        latestReading ? (
                                                            <MDTypography variant="h6" component="h2" textTransform="capitalize">
                                                                Current reading - {measurementPoints[0].pointName}: {latestReading.value}{getUnit(measurementPoints[0].pointName)}
                                                            </MDTypography>
                                                        ) : <></>
                                                    }
                                                    {/*{*/}
                                                    {/*    threshold ? (*/}
                                                    {/*        <MDTypography variant="h6" component="h2" textTransform="capitalize">*/}
                                                    {/*            Threshold: {threshold}°C*/}
                                                    {/*        </MDTypography>*/}
                                                    {/*    ) : <></>*/}
                                                    {/*}*/}
                                                    {/*{*/}
                                                    {/*    daysExceedThreshold && getUnit(measurementPoints[0].pointName) ? (*/}
                                                    {/*        <MDTypography variant="h6" component="h2" textTransform="capitalize">*/}
                                                    {/*            Days Exceeding Threshold: {daysExceedThreshold}*/}
                                                    {/*        </MDTypography>*/}
                                                    {/*    ) : <></>*/}
                                                    {/*}*/}
                                                    <MDBox display="flex" alignItems="center">
                                                        <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                                                            <Icon>schedule</Icon>
                                                        </MDTypography>
                                                        <MDTypography variant="button" fontWeight="light" component="h2">
                                                            {latestReading ? `${latestReading.time}` : ''}
                                                        </MDTypography>
                                                    </MDBox>
                                                </MDBox>

                                                {/* Legend Section */}
                                                <MDBox style={{ textAlign: 'center' }}>
                                                    <MDTypography variant="h6" color="text" algin="center"> Legend:</MDTypography>
                                                    <MDBox ml={2}>
                                                        <MDBox display="flex" alignItems="center" mb={1}>
                                                            <Box
                                                                sx={{
                                                                    width: 16, // Smaller width
                                                                    height: 16, // Rectangular height
                                                                    backgroundColor: "red",
                                                                    marginRight: 1,
                                                                }}
                                                                // sx={{
                                                                //     width: 20,
                                                                //     height: 20,
                                                                //     backgroundColor: "red",
                                                                //     borderRadius: "50%",
                                                                //     marginRight: 1,
                                                                // }}
                                                            />
                                                            <MDTypography variant="button" fontWeight="light" color="text" component="h2">Hourly Average Temperature above 27°C</MDTypography>
                                                        </MDBox>
                                                        <MDBox display="flex" alignItems="center" mb={1}>
                                                            <Box
                                                                sx={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    backgroundColor: "#71C17C",
                                                                    // borderRadius: "50%",
                                                                    marginRight: 1,
                                                                }}
                                                            />
                                                            <MDTypography variant="button" fontWeight="light" color="text" component="h2">Hourly Average Temperature between 25-27°C</MDTypography>
                                                        </MDBox>
                                                        <MDBox display="flex" alignItems="center" mb={1}>
                                                            <Box
                                                                sx={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    backgroundColor: "#FFD700",
                                                                    // borderRadius: "50%",
                                                                    marginRight: 1,
                                                                }}
                                                            />
                                                            <MDTypography variant="button" fontWeight="light" color="text" component="h2">Hourly Average Temperature between 24-25°C</MDTypography>
                                                        </MDBox>
                                                        <MDBox display="flex" alignItems="center" mb={1}>
                                                            <Box
                                                                sx={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    backgroundColor: "#AFDCEB",
                                                                    // borderRadius: "50%",
                                                                    marginRight: 1,
                                                                }}
                                                            />
                                                            <MDTypography variant="button" fontWeight="light" color="text" component="h2">Hourly Average Temperature below 24°C</MDTypography>
                                                        </MDBox>
                                                        <MDBox display="flex" alignItems="center">
                                                            <Box
                                                                sx={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    backgroundColor: "#bfbfbf",
                                                                    // borderRadius: "50%",
                                                                    marginRight: 1,
                                                                }}
                                                            />
                                                            <MDTypography variant="button" fontWeight="light" color="text" component="h2">Data Not Available</MDTypography>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>

                                            <MDBox mt={0} py={2}>
                                                <MDTypography variant="caption" color="secondary" style={{ display: 'block' }}>
                                                    * Note: If you notice and require any help for issues on sensor information, kindly contact ED through raising an EDD Service Request.
                                                </MDTypography>
                                            </MDBox>
                                            {/*{chartData && <DefaultLineChart chart={chartData} />}*/}
                                            <iframe
                                                src={`${process.env.REACT_APP_CHART_URL}?embedded=true&deviceId=${deviceId}&threshold=${threshold}`}
                                                frameBorder="0"
                                                width="100%"
                                                height="600px"
                                            >
                                            </iframe>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer />
            </div>
        </DashboardLayout>
    );
};

export default DeviceDetailsPage;


//
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { Button } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
// import Icon from "@mui/material/Icon";
// import AddIcon from "@mui/icons-material/Add";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import IconButton from "@mui/material/IconButton";
// import ChartModal from "./chartModal";
// import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
// import MDBox from "../../../../components/MDBox";
// import MDTypography from "../../../../components/MDTypography";
// import MDInput from "../../../../components/MDInput";
// import MDButton from "../../../../components/MDButton";
// import DeviceEditor from "./DeviceEditor";
// import PointEditor from "./PointEditor";
// import DataTable from "../../../../examples/Tables/DataTable";
// import Footer from "../../../../examples/Footer";
// import MapModal from "./mapModal";
// import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
// import DefaultLineChart from '../../../../examples/Charts/LineCharts/DefaultLineChart';
// import ReadingService from '../../../../services/reading-service';
// import DeviceService from "../../../../services/device-service";
// import MeasurementService from "../../../../services/measurement-service";
// import FileService from '../../../../services/file-service';
// import formatDateTime from '../../../../utils/formatDateTime';
//
// const AGGREGATION_FILE = process.env.REACT_APP_AGGREGATION_FILE;
// const DATA_BUCKET = process.env.REACT_APP_DATA_BUCKET;
//
// const DeviceDetailsPage = () => {
//     const { deviceId } = useParams(); // get the deviceId from the URL
//     const [device, setDevice] = useState(null);
//
//     const [chartData, setChartData] = useState([]);
//     const [deviceName, setDeviceName] = useState('');
//     const [measurementPoints, setMeasurementPoints] = useState([]);
//     const [latestReading, setLatestReading] = useState(null);
//     const [weeklyAverage, setWeeklyAverage] = useState(null);
//
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//
//     const style = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: isMobile ? '100%' : '90%',
//         bgcolor: 'background.paper',
//         borderRadius: 4,
//         boxShadow: 24,
//         p: isMobile ? 2 : 4,
//     };
//
//     useEffect(() => {
//         if (deviceId) {
//             const fetchDeviceDetails = async () => {
//                 const deviceDetails = await DeviceService.getDevice(deviceId);
//                 setDeviceName(deviceDetails.deviceName);
//                 const measurementResponse = await MeasurementService.listMeasurementPoints();
//                 const measurementResults = measurementResponse.collection;
//                 const filteredMeasurementPoints = measurementResults.filter(point => point.deviceId === deviceId);
//                 setMeasurementPoints(filteredMeasurementPoints); // Filter measurement points by deviceId
//                 const aggregation = await FileService.getFileContent(AGGREGATION_FILE, DATA_BUCKET);
//                 const aggMap = {};
//                 aggregation.forEach(e => { aggMap[e.deviceId + ""] = e });
//                 // const deviceAggregate = aggMap[deviceId];
//                 const deviceAggregate = aggMap[deviceId + ""];
//                 if (deviceAggregate) {
//                     // setWeeklyAverage(deviceAggregate.pointValue);
//                     setWeeklyAverage(deviceAggregate['mean']);
//                 }
//             };
//             fetchDeviceDetails();
//         }
//     }, [deviceId]);
//
//     useEffect(() => {
//         if (deviceId && measurementPoints.length > 0) {
//             const fetchData = async () => {
//                 const startTime = new Date();
//                 startTime.setHours(startTime.getHours() - 24 * 7); // Fetch data for the last 1 week
//                 const endTime = new Date();
//
//                 const readingResults = await ReadingService.getReadingsByIdAndDate(
//                     deviceId,
//                     startTime.toISOString(),
//                     endTime.toISOString()
//                 );
//
//                 const labels = readingResults.map(reading => formatDateTime(new Date(reading.dateTime)).formattedDateTime).reverse();
//                 const data = readingResults.map(reading => reading.pointData.value).reverse();
//
//                 setChartData({
//                     labels,
//                     datasets: [
//                         {
//                             label: measurementPoints[0].pointName,
//                             data,
//                             color: 'info',
//                             fill: true,
//                             tension: 1,
//                         },
//                     ],
//                 });
//
//                 if (readingResults.length > 0) {
//                     const latest = readingResults[0];
//                     setLatestReading({
//                         value: latest.pointData.value,
//                         time: formatDateTime(new Date(latest.dateTime)).formattedDateTimeFull
//                     });
//                 }
//             };
//
//             fetchData();
//         }
//     }, [deviceId, measurementPoints]);
//
//     const getUnit = (pointName) => {
//         return pointName === 'temperature' ? '°C' : '';
//     };
//
//     return (
//         <DashboardLayout>
//             <div style={{ display: "flex", flexDirection: "column", minHeight:"95vh", justifyContent: "space-between" }}>
//                 <div>
//                     <DashboardNavbar />
//
//                     <MDBox pt={6} pb={3}>
//                         <Grid container spacing={6}>
//                             <Grid item xs={12}>
//                                 <Card>
//                                     <MDBox
//                                         mx={2}
//                                         mt={-3}
//                                         py={3}
//                                         px={2}
//                                         variant="gradient"
//                                         bgColor="info"
//                                         borderRadius="lg"
//                                         coloredShadow="info"
//                                         style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
//                                     >
//                                         <MDTypography variant="h6" color="white" >
//                                             Device Reading
//                                         </MDTypography>
//                                         <Box sx={style}>
//                                             <MDTypography variant="h5" component="h2" textTransform="capitalize">
//                                                 {deviceName}
//                                             </MDTypography>
//                                             <MDTypography variant="h6" component="h2" textTransform="capitalize">
//                                                 {latestReading
//                                                     ? `Current reading - ${measurementPoints[0].pointName}: ${latestReading.value}${getUnit(measurementPoints[0].pointName)}`
//                                                     : 'No recent readings for the past 24 hours'}
//                                             </MDTypography>
//                                             <MDTypography variant="h6" component="h2" textTransform="capitalize">
//                                                 {weeklyAverage
//                                                     ? `Weekly Average - ${measurementPoints[0].pointName}: ${weeklyAverage}${getUnit(measurementPoints[0].pointName)}`
//                                                     : 'No weekly average available'}
//                                             </MDTypography>
//                                             <MDBox display="flex" alignItems="center">
//                                                 <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
//                                                     <Icon>schedule</Icon>
//                                                 </MDTypography>
//                                                 <MDTypography variant="button" fontWeight="light" component="h2">
//                                                     {latestReading
//                                                         ? `${latestReading.time}`
//                                                         : ''
//                                                     }
//                                                 </MDTypography>
//                                             </MDBox>
//                                             <DefaultLineChart chart={chartData} />
//                                         </Box>
//                                     </MDBox>
//                                 </Card>
//                             </Grid>
//                         </Grid>
//                     </MDBox>
//                 </div>
//                 <Footer />
//             </div>
//         </DashboardLayout>
//
//     );
// };
//
// export default DeviceDetailsPage;
