/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import ComplexStatisticsCard from "../../../../examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Services and Utilities
import CpumetricService from "../../../../services/cpumetric-service";
import ReadingService from "../../../../services/reading-service";
import formatDateTime from "../../../../utils/formatDateTime";

const CpuMetric = () => {
    const refreshInterval = 5*1000;
    const [cpuUsage, setCpuUsage] = useState(0.0);
    const [memoryUsage, setMemoryUsage] = useState(0.0);
    const [mqttLoad, setMqttLoad] = useState(0);
    const [cpuColor, setCpuColor] = useState("info");
    const [memoryColor, setMemoryColor] = useState("info");
    const [mqttLoadColor, setMqttLoadColor] = useState("info");
    const [lastUpdated, setLastUpdated] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cpuMetric = await CpumetricService.getCPUMetric();
                setCpuUsage(cpuMetric.cpu);
                setMemoryUsage(cpuMetric.usedMemPercentage);
                setLastUpdated(formatDateTime(new Date()).formattedDateTimeFull);
                if (cpuMetric.cpu > 40) {
                    setCpuColor("error");
                } else if (cpuMetric.cpu > 20) {
                    setCpuColor("warning");
                } else {
                    setCpuColor("success");
                }
                // const endTime = new Date();
                // const startTime = new Date(endTime - 60000);
                // const dataOneMin = await ReadingService.getReadings(startTime.toISOString(), endTime.toISOString());
                // setMqttLoad(dataOneMin.length);
                setMqttLoad("-");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const interval = setInterval(fetchData, refreshInterval);

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, []); // Empty dependency array ensures this effect runs once on mount

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                    <ComplexStatisticsCard
                        title={'-----'}
                        color={cpuColor}
                        pointTitle={"Backend CPU Usage (%)"}
                        count={cpuUsage}
                        icon="computer"
                        time={{
                            color: "success",
                            label: `Last Updated ${lastUpdated}`,
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                    <ComplexStatisticsCard
                        color={memoryColor}
                        title={'-----'}
                        pointTitle={"Backend Memory Usage (%)"}
                        count={memoryUsage}
                        icon="computer"
                        time={{
                            color: "success",
                            label: `Last Updated ${lastUpdated}`,
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                    <ComplexStatisticsCard
                        color={mqttLoadColor}
                        title={'-----'}
                        pointTitle={"MQTT Load Per Minute"}
                        count={mqttLoad}
                        icon="computer"
                        time={{
                            color: "success",
                            label: `Last Updated ${lastUpdated}`,
                        }}
                    />
                </MDBox>
            </Grid>
        </Grid>
    );
};

export default CpuMetric;
//
//
// const CpuMetric = () => {
//
//     const refreshInterval = 5*1000;
//     const [cpuUsage, setCpuUsage] = useState(0.0);
//     const [memoryUsage, setMemoryUsage] = useState(0.0);
//     const [mqttLoad, setMqttLoad] = useState(0);
//     const [cpuColor, setCpuColor] = useState("info");
//     const [memoryColor, setMemoryColor] = useState("info");
//     const [mqttLoadColor, setMqttLoadColor] = useState("info");
//     const [lastUpdated, setLastUpdated] = useState("");
//
//     useEffect(() => {
//         const interval = setInterval(() => {
//             CpumetricService.getCPUMetric().then((res) => {
//                 setCpuUsage(res.cpu);
//                 setMemoryUsage(res.usedMemPercentage);
//                 setLastUpdated(formatDateTime(new Date()).formattedDateTimeFull);
//                 if (res.cpu > 40) {
//                     setCpuColor("error");
//                 } else if (res.cpu > 20) {
//                     setCpuColor("warning");
//                 } else {
//                     setCpuColor("success");
//                 }
//             });
//
//             let endTime = new Date();
//             let startTime = new Date();
//
//             endTime.setHours(new Date().getHours() - 9);
//             startTime.setHours(new Date().getHours() - 10);
//
//             startTime.setMinutes(endTime.getMinutes() - 1);
//
//             console.log("startTime: ", startTime);
//             console.log("endTime: ", endTime);
//
//             const promises = [
//                 ReadingService.getReadingsByIdAndDate(1, startTime.toISOString(), endTime.toISOString()),
//                 ReadingService.getReadingsByIdAndDate(2, startTime.toISOString(), endTime.toISOString()),
//                 ReadingService.getReadingsByIdAndDate(3, startTime.toISOString(), endTime.toISOString()),
//             ];
//
//             const readingResults = Promise.all(promises);
//             const mqttLoad = readingResults.reduce((total, readings) => total + readings.length, 0);
//
//             setMqttLoad(mqttLoad);
//
//             console.log("mqttLoad: ", mqttLoad);
//
//             // readingService.getReadingsByIdAndDate(2,startTime.toISOString(), endTime.toISOString()).then((res) => {
//             //     console.log("res: ", res);
//             //     setMqttLoad(res.length);
//             //     console.log("res.length: ", res.length);
//             // });
//
//         }, refreshInterval);
//
//
//         // Cleanup the interval on component unmount
//         return () => clearInterval(interval);
//     }, []); // Empty dependency array ensures this effect runs once on mount
//
//     return (
//         <Grid container spacing={3}>
//             <Grid item xs={12} md={6} lg={4}>
//                 <MDBox mb={2}>
//                     <ComplexStatisticsCard
//                         title={'-----'}
//                         color={cpuColor}
//                         pointTitle={"Backend CPU Usage (%)"}
//                         count={cpuUsage}
//                         icon="computer"
//                         time={{
//                             color: "success",
//                             label: `Last Updated ${lastUpdated}`,
//                         }}
//                     />
//                 </MDBox>
//             </Grid>
//             <Grid item xs={12} md={6} lg={4}>
//                 <MDBox mb={2}>
//                     <ComplexStatisticsCard
//                         color={memoryColor}
//                         title={'-----'}
//                         pointTitle={"Backend Memory Usage (%)"}
//                         count={memoryUsage}
//                         icon="computer"
//                         time={{
//                             color: "success",
//                             label: `Last Updated ${lastUpdated}`,
//                         }}
//                     />
//                 </MDBox>
//             </Grid>
//             <Grid item xs={12} md={6} lg={4}>
//                 <MDBox mb={2}>
//                     <ComplexStatisticsCard
//                         color={mqttLoadColor}
//                         title={'-----'}
//                         pointTitle={"MQTT Load Per Minute"}
//                         count={mqttLoad}
//                         icon="computer"
//                         time={{
//                             color: "success",
//                             label: `Last Updated ${lastUpdated}`,
//                         }}
//                     />
//                 </MDBox>
//             </Grid>
//         </Grid>
//     );
// };
//
// export default CpuMetric;

