import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../index';

const useInactivityTimeout = (timeout = 30 * 60 * 1000) => { // default to 30 minutes
    const [lastActivity, setLastActivity] = useState(Date.now());
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);

    const handleActivity = useCallback(() => {
        setLastActivity(Date.now());
    }, []);

    const handleLogout = useCallback(() => {
        localStorage.clear();
        logout();
        navigate('/auth/login');
    }, [logout, navigate]);

    useEffect(() => {
        const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'scroll'];

        const debouncedHandleActivity = debounce(handleActivity, 300);

        events.forEach(event => window.addEventListener(event, debouncedHandleActivity));

        const interval = setInterval(() => {
            if (Date.now() - lastActivity > timeout) {
                handleLogout();
            }
        }, 1000);

        const handleUnload = () => {
            localStorage.clear();
        };

        window.addEventListener('unload', handleUnload);

        return () => {
            events.forEach(event => window.removeEventListener(event, debouncedHandleActivity));
            window.removeEventListener('unload', handleUnload);
            clearInterval(interval);
        };
    }, [lastActivity, timeout, handleActivity, handleLogout]);

    return null;
};

// Simple debounce function
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export default useInactivityTimeout;